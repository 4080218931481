import {
  BONK_MINT_PK,
  DUAL_MINT_PK,
  JITOSOL_MINT_PK,
  MNGO_MINT_PK,
  MSOL_MINT_PK,
  PYTH_BTC_PK,
  PYTH_ETH_PK,
  PYTH_JITOSOL_PK,
  PYTH_MNGO_PK,
  PYTH_MSOL_PK,
  PYTH_SOL_PK,
  RAY_MINT_PK,
  USDC_MINT_PK,
  WBTC_PORTAL_MINT_PK,
  TBTC_MINT_PK,
  WETH_PORTAL_MINT_PK,
  WSOL_PK,
} from '@dual-finance/dip';
import { PublicKey } from '@solana/web3.js';
import React, { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { DUAL_API_MAINNET, EXPLORER, RPC_URL_MAINNET } from './config/config';
import {
  CHAI_MINT_PK,
  USDY_MINT_PK,
  ALL_MINT_PK,
  DEAN_MINT_PK,
  T_MINT_PK,
  WSTETH_MINT_PK,
  GUAC_MINT_PK,
  RETH_MINT_PK,
  SLCL_MINT_PK,
  NOS_MINT_PK,
  BUTTER_MINT_PK,
  GECKO_MINT_PK,
  ELON_MINT_PK,
  GOFX_MINT_PK,
  MOUTAI_MINT_PK,
  PYTH_MINT_PK,
  DRAKO_MINT_PK,
  BANX_MINT_PK,
  DUALSOL_MINT_PK,
  ADVOCAT_MINT_PK,
  SAMO_MINT_PK,
  LABS_MINT_PK,
  SCOIN_MINT_PK,
} from './constants/addresses';

type Environment = {
  network: string;
  explorer: string;
  settings: {
    setNetwork: Dispatch<SetStateAction<string>>;
    setExplorer: Dispatch<SetStateAction<string>>;
  };
  ASSETS: {
    BTC: PublicKey;
    wBTC: PublicKey;
    ETH: PublicKey;
    SOL: PublicKey;
    USDC: PublicKey;
    MNGO: PublicKey;
    BONK: PublicKey;
    RAY: PublicKey;
    DUAL: PublicKey;
    DEAN: PublicKey;
    T: PublicKey;
    jitoSOL: PublicKey;
    CHAI: PublicKey;
    USDY: PublicKey;
    ALL: PublicKey;
    wstETH: PublicKey;
    rETH: PublicKey;
    mSOL: PublicKey;
    GUAC: PublicKey;
    SLCL: PublicKey;
    NOS: PublicKey;
    BUTTER: PublicKey;
    GECKO: PublicKey;
    ELON: PublicKey;
    GOFX: PublicKey;
    MOUTAI: PublicKey;
    PYTH: PublicKey;
    DRAKO: PublicKey;
    BANX: PublicKey;
    DUALSOL: PublicKey;
    ADVOCAT: PublicKey;
    SAMO: PublicKey;
    LABS: PublicKey;
    SCOIN: PublicKey;
  };
  DUAL_API: string;
  PYTH: {
    BTC: PublicKey;
    ETH: PublicKey;
    SOL: PublicKey;
    MNGO: PublicKey;
    jitoSOL: PublicKey;
    mSOL: PublicKey;
  };
  features: {
    mango: boolean;
  };
};

const defaultEnvironment: Environment = {
  network: RPC_URL_MAINNET,
  explorer: EXPLORER.solscan,
  settings: {
    setNetwork: () => {},
    setExplorer: () => {},
  },
  ASSETS: {
    BTC: TBTC_MINT_PK,
    wBTC: WBTC_PORTAL_MINT_PK,
    ETH: WETH_PORTAL_MINT_PK,
    SOL: WSOL_PK,
    USDC: USDC_MINT_PK,
    MNGO: MNGO_MINT_PK,
    BONK: BONK_MINT_PK,
    RAY: RAY_MINT_PK,
    DUAL: DUAL_MINT_PK,
    DEAN: DEAN_MINT_PK,
    T: T_MINT_PK,
    jitoSOL: JITOSOL_MINT_PK,
    CHAI: CHAI_MINT_PK,
    USDY: USDY_MINT_PK,
    ALL: ALL_MINT_PK,
    wstETH: WSTETH_MINT_PK,
    rETH: RETH_MINT_PK,
    mSOL: MSOL_MINT_PK,
    GUAC: GUAC_MINT_PK,
    SLCL: SLCL_MINT_PK,
    NOS: NOS_MINT_PK,
    BUTTER: BUTTER_MINT_PK,
    GECKO: GECKO_MINT_PK,
    ELON: ELON_MINT_PK,
    GOFX: GOFX_MINT_PK,
    MOUTAI: MOUTAI_MINT_PK,
    PYTH: PYTH_MINT_PK,
    DRAKO: DRAKO_MINT_PK,
    BANX: BANX_MINT_PK,
    DUALSOL: DUALSOL_MINT_PK,
    ADVOCAT: ADVOCAT_MINT_PK,
    SAMO: SAMO_MINT_PK,
    LABS: LABS_MINT_PK,
    SCOIN: SCOIN_MINT_PK,
  },
  DUAL_API: DUAL_API_MAINNET,
  PYTH: {
    BTC: PYTH_BTC_PK,
    ETH: PYTH_ETH_PK,
    SOL: PYTH_SOL_PK,
    MNGO: PYTH_MNGO_PK,
    mSOL: PYTH_MSOL_PK,
    jitoSOL: PYTH_JITOSOL_PK,
  },
  features: {
    mango: false,
  },
};

export type EnvironmentAssets = Environment['ASSETS'];
export type EnvironmentPyth = Environment['PYTH'];
export type EnvironmentFeatures = Environment['features'];

const EnvironmentContext = createContext(defaultEnvironment);

const EnvironmentContextProvider = ({
  environment,
  children,
}: {
  environment: Environment;
  children: React.ReactNode;
}) => {
  return (
    // the Provider gives access to the context to its children
    <EnvironmentContext.Provider value={environment}>{children}</EnvironmentContext.Provider>
  );
};

const useEnvironment = () => useContext(EnvironmentContext);
const useFeatureFlags = () => useEnvironment().features;

export { EnvironmentContext, EnvironmentContextProvider, useEnvironment, useFeatureFlags };
