import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Layout } from './components/Layout';
import { LoadingBar } from './components/LoadingBar';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, lazy: () => import('./pages/home') },
      { path: 'pools', lazy: () => import('./pages/pools') },
      { path: 'pools/:id', lazy: () => import('./pages/pools') },
      { path: 'balance', lazy: () => import('./pages/balance') },
      { path: 'pricing', lazy: () => import('./pages/pricing') },
      { path: 'studio', lazy: () => import('./pages/studio') },
      { path: 'loan', lazy: () => import('./pages/loan') },
      { path: 'calc', lazy: () => import('./pages/calc') },
      { path: 'api', lazy: () => import('./pages/api') },
      { path: 'airdrop', lazy: () => import('./pages/airdrop') },
      { path: 'upload', lazy: () => import('./pages/upload') },
      { path: 'openbook', lazy: () => import('./pages/openbook') },
      { path: 'bridge', lazy: () => import('./pages/bridge') },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} fallbackElement={<LoadingBar />} />;
}
