import { AnchorProvider } from '@project-serum/anchor';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection } from '@solana/web3.js';
import { useMemo } from 'react';
import { useEnvironment } from '../EnvironmentContext';
import { GetProvider } from '../utils/utils';
import { useQueryParam } from './useQueryParams';

export function useAnchorProvider(): [AnchorProvider, Connection] {
  const skipPreflight = useQueryParam('skipPreflight');
  const wallet = useWallet();
  const { network } = useEnvironment();
  const [provider, connection] = useMemo(() => {
    return GetProvider(wallet, network, { skipPreflight: skipPreflight !== null });
  }, [wallet, network, skipPreflight]);
  return [provider, connection];
}
