import { Connection, PublicKey } from '@solana/web3.js';
import { getMint } from '@solana/spl-token';
import { publicKey } from '@metaplex-foundation/umi';
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { fetchDigitalAsset, mplTokenMetadata } from '@metaplex-foundation/mpl-token-metadata';
import { MintAccount, MintJSON } from './types';

interface MintCache {
  data: MintJSON;
}

export async function fetchMintWithCache(connection: Connection, address: PublicKey): Promise<MintAccount> {
  const cacheKey = `mint-${address.toString()}`;
  const cached = localStorage.getItem(cacheKey);
  if (cached) {
    const { data } = JSON.parse(cached) as MintCache;
    return MintAccount.fromJSON(data);
  }

  const mintAccount = await fetchMint(connection, address);
  localStorage.setItem(cacheKey, JSON.stringify({ timestamp: new Date().getTime(), data: mintAccount.toJSON() }));
  return mintAccount;
}

export async function fetchMint(connection: Connection, address: PublicKey): Promise<MintAccount> {
  const mint = await getMint(connection, address);

  const umi = createUmi(connection).use(mplTokenMetadata());
  let metadata;
  let json;
  try {
    const asset = await fetchDigitalAsset(umi, publicKey(address));
    metadata = asset.metadata;
    const uri = asset.metadata.uri.replace(/\0.*$/g, '');
    const data = await fetch(uri);
    json = await data.json();
  } catch (e) {
    // can fail for various reasons: metadata account doesn't exist,
    // or fails to fetch the json; either way fail silently
  }

  const symbol = metadata?.symbol;
  const name = metadata?.name;
  const mintAccount = new MintAccount({ ...mint, metadata: { symbol, name, ...json } });
  return mintAccount;
}
