import {
  BONK_DEV_MINT_PK,
  BONK_MINT_PK,
  BTC_DEV_MINT_PK,
  DUAL_MINT_PK,
  ETH_DEV_MINT_PK,
  JITOSOL_MINT_PK,
  MNGO_MINT_PK,
  MSOL_MINT_PK,
  RAY_DEV_MINT_PK,
  RAY_MINT_PK,
  USDC_DEV_MINT_PK,
  USDC_MINT_PK,
  WBTC_PORTAL_MINT_PK,
  TBTC_MINT_PK,
  WETH_PORTAL_MINT_PK,
  WSOL_PK,
} from '@dual-finance/dip';
import { PublicKey } from '@solana/web3.js';
import {
  CHAI_MINT_PK,
  USDY_MINT_PK,
  ALL_MINT_PK,
  DEAN_MINT_PK,
  T_MINT_PK,
  DUAL_DEV_MINT_PK,
  WSTETH_MINT_PK,
  GUAC_MINT_PK,
  RETH_MINT_PK,
  SLCL_MINT_PK,
  NOS_MINT_PK,
  BUTTER_MINT_PK,
  GECKO_MINT_PK,
  ELON_MINT_PK,
  GOFX_MINT_PK,
  MOUTAI_MINT_PK,
  PYTH_MINT_PK,
  DRAKO_MINT_PK,
  BANX_MINT_PK,
  DUALSOL_MINT_PK,
  ADVOCAT_MINT_PK,
  SAMO_MINT_PK,
  LABS_MINT_PK,
  SCOIN_MINT_PK,
} from '../constants/addresses';

// export const RPC_SENDER_URL_MAINNET = 'https://rpc.mngo.cloud/hldxfiaxk45r';
// TODO: Ensure SENDER URL is actually being used
export const RPC_SENDER_URL_MAINNET = 'https://mainnet.helius-rpc.com/?api-key=b1190660-ff5b-4b41-ad0d-386bcd646257';
export const RPC_URL_MAINNET = 'https://mainnet.helius-rpc.com/?api-key=c39a32e5-43a9-4514-807a-ee2b8d7338e8';
export const API_URL_DEVNET = 'https://solana-devnet.g.alchemy.com/v2/e5EQixWHc-n0F3JTe-ueWzKZIJDMYXTi';
export const HELIUS_MAINNET = 'https://mainnet.helius-rpc.com/?api-key=b1190660-ff5b-4b41-ad0d-386bcd646257';
// export const HELIUS_MAINNET = 'https://enormous-farrand-fast-mainnet.helius-rpc.com/';
export const HELIUS_DEVNET = 'https://complex-lynsey-fast-devnet.helius-rpc.com/';
export const LOCALNET = 'http://127.0.0.1:8899';
export const DUAL_API_MAINNET = 'https://api.dual.finance';
export const DUAL_API_DEVNET = 'https://dev.api.dual.finance';
export const DISCORD_TICKET_LINK = 'https://discord.com/invite/jvxp8GNZxR';
export const GITBOOK_DOCS_LINK = 'https://dual-finance.gitbook.io/dual-finance-documentation/';

export const EXPLORER = {
  solscan: 'https://solscan.io',
  solanafm: 'https://solana.fm',
  solexplorer: 'https://explorer.solana.com',
};

export const TOKENS: DipAsset[] = ['SOL', 'mSOL', 'jitoSOL', 'BTC', 'ETH', 'MNGO'];
export const ENABLED_DIP_TOKENS: DipAsset[] = ['SOL', 'mSOL', 'jitoSOL', 'BTC', 'MNGO'];
export type DipAsset = 'SOL' | 'BTC' | 'ETH' | 'MNGO' | 'mSOL' | 'jitoSOL';
export type PremiumAsset = 'USDC';

export const MIN_PREMIUM = 1; // Min stake size in USDC premium extractable
export const MAX_PREMIUM = 1000; // Max stake size in USDC premium extractable

// TODO: Fallback to a metaplex lookup if this cache doesnt work
export const PK_TO_ASSET = {
  [TBTC_MINT_PK.toBase58()]: 'BTC',
  [WBTC_PORTAL_MINT_PK.toBase58()]: 'wBTC',
  [BTC_DEV_MINT_PK.toBase58()]: 'BTC',
  [WETH_PORTAL_MINT_PK.toBase58()]: 'ETH',
  [ETH_DEV_MINT_PK.toBase58()]: 'ETH',
  [WSOL_PK.toBase58()]: 'SOL',
  [USDC_DEV_MINT_PK.toBase58()]: 'USDC',
  [USDC_MINT_PK.toBase58()]: 'USDC',
  [MNGO_MINT_PK.toBase58()]: 'MNGO',
  [BONK_MINT_PK.toBase58()]: 'BONK',
  [BONK_DEV_MINT_PK.toBase58()]: 'BONK',
  [RAY_MINT_PK.toBase58()]: 'RAY',
  [RAY_DEV_MINT_PK.toBase58()]: 'RAY',
  [DUAL_MINT_PK.toBase58()]: 'DUAL',
  [DUAL_DEV_MINT_PK.toBase58()]: 'DUAL',
  [MSOL_MINT_PK.toBase58()]: 'mSOL',
  [JITOSOL_MINT_PK.toBase58()]: 'jitoSOL',
  [DEAN_MINT_PK.toBase58()]: 'DEAN',
  [T_MINT_PK.toBase58()]: 'T',
  [CHAI_MINT_PK.toBase58()]: 'CHAI',
  [USDY_MINT_PK.toBase58()]: 'USDY',
  [ALL_MINT_PK.toBase58()]: 'ALL',
  [WSTETH_MINT_PK.toBase58()]: 'wstETH',
  [GUAC_MINT_PK.toBase58()]: 'GUAC',
  [RETH_MINT_PK.toBase58()]: 'rETH',
  [SLCL_MINT_PK.toBase58()]: 'SLCL',
  [NOS_MINT_PK.toBase58()]: 'NOS',
  [BUTTER_MINT_PK.toBase58()]: 'BUTTER',
  [GECKO_MINT_PK.toBase58()]: 'GECKO',
  [ELON_MINT_PK.toBase58()]: 'ELON',
  [GOFX_MINT_PK.toBase58()]: 'GOFX',
  [MOUTAI_MINT_PK.toBase58()]: 'MOUTAI',
  [PYTH_MINT_PK.toBase58()]: 'PYTH',
  [DRAKO_MINT_PK.toBase58()]: 'DRAKO',
  [BANX_MINT_PK.toBase58()]: 'BANX',
  [DUALSOL_MINT_PK.toBase58()]: 'dualSOL',
  [ADVOCAT_MINT_PK.toBase58()]: 'ADVOCAT',
  [SAMO_MINT_PK.toBase58()]: 'SAMO',
  [LABS_MINT_PK.toBase58()]: 'LABS',
  [SCOIN_MINT_PK.toBase58()]: 'SCOIN',
  LSO: 'LSO',
};

export const VOL_MAP = {
  [TBTC_MINT_PK.toBase58()]: 0.2,
  [WBTC_PORTAL_MINT_PK.toBase58()]: 0.2,
  [BTC_DEV_MINT_PK.toBase58()]: 0.2,
  [WETH_PORTAL_MINT_PK.toBase58()]: 0.25,
  [ETH_DEV_MINT_PK.toBase58()]: 0.25,
  [WSOL_PK.toBase58()]: 0.3,
  [MNGO_MINT_PK.toBase58()]: 0.35,
  [BONK_MINT_PK.toBase58()]: 0.35,
  [BONK_DEV_MINT_PK.toBase58()]: 0.35,
  [RAY_MINT_PK.toBase58()]: 0.35,
  [RAY_DEV_MINT_PK.toBase58()]: 0.35,
  [DUAL_MINT_PK.toBase58()]: 0.5,
  [MSOL_MINT_PK.toBase58()]: 0,
  [JITOSOL_MINT_PK.toBase58()]: 0,
};

export const SO_LIQ_LIST = ['GSOBONKMAS-USDC'];

// Most vol ratio that an MM bid can be over the bve.
export const MM_BID_LIMIT = 3;

// Percent Range to allow strike creation in Pricing
export const STRIKE_RANGE = 0.65;

export const DUAL_PRICE = 0.035;

export const LSO_RATIO = 0.5;

export const SLIPPAGE_FACTOR = 0.25;

// Minimum Liquidation Value
export const MIN_LIQ_VALUE = 0.001;

// Only display dip APY in this range. Primarily used to remove those below a 1%
// threshold so the page isnt clogged with low APYs from very OTM DIPs. The max
// is a protection in case near expiration is going to be displayed.
export const MIN_DIP_APY_TO_DISPLAY = 0.01;
export const MAX_DIP_APY_TO_DISPLAY = 10;

export const AIRDROPS = new Map<string, PublicKey>(
  Object.entries({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    // 'MNGO-Buyback-May-24': new PublicKey('Eq3WrffTRWkKFxMUXiQxUd4XFwxD5diFDo8pAv1NLkR2'),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    // 'Star Atlas Test': new PublicKey('x9Qd6JM2fCwdLnz7jr7iByRiycxLCPvT777qYnoEdFK'),
  })
);
