import { useLocalStorage, useWallet } from '@solana/wallet-adapter-react';
import { Link } from 'react-router-dom';
import { useAirdropBalances } from '../../hooks/useAirdropBalances';
import styles from './AirdropBanner.module.scss';

export function AirdropBanner() {
  const wallet = useWallet();
  const [isHidden, setIsHidden] = useLocalStorage('show-airdrop-banner', false);
  const { balanceByAirdropId } = useAirdropBalances(wallet.publicKey);
  const isQualified = balanceByAirdropId ? Object.keys(balanceByAirdropId).length > 0 : false;

  if (!isQualified || isHidden) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div />
        <Link to="/airdrop" style={{ color: 'white' }}>
          Wallet qualifies for airdrops, click to claim!
        </Link>
        <button type="button" className={styles.cancel} onClick={() => setIsHidden(true)}>
          X
        </button>
      </div>
    </div>
  );
}
