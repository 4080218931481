import { useEffect, useState } from 'react';
import styles from './LoadingBar.module.scss';

export function LoadingBar() {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setWidth((oldWidth) => {
        if (oldWidth < 100) {
          return oldWidth + 10;
        }
        clearInterval(interval);
        return 100;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content} style={{ width: `${width}%` }} />
    </div>
  );
}
