import React, { useMemo } from 'react';
import { ConnectionProvider, useLocalStorage, WalletProvider } from '@solana/wallet-adapter-react';
import { GlowWalletAdapter, PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
  BONK_DEV_MINT_PK,
  BONK_MINT_PK,
  BTC_DEV_MINT_PK,
  DUAL_MINT_PK,
  ETH_DEV_MINT_PK,
  JITOSOL_MINT_PK,
  MNGO_MINT_PK,
  MSOL_MINT_PK,
  PYTH_BTC_DEV_PK,
  PYTH_BTC_PK,
  PYTH_ETH_DEV_PK,
  PYTH_ETH_PK,
  PYTH_JITOSOL_DEV_PK,
  PYTH_JITOSOL_PK,
  PYTH_MNGO_DEV_PK,
  PYTH_MNGO_PK,
  PYTH_MSOL_PK,
  PYTH_SOL_DEV_PK,
  PYTH_SOL_PK,
  RAY_DEV_MINT_PK,
  RAY_MINT_PK,
  USDC_DEV_MINT_PK,
  USDC_MINT_PK,
  WBTC_PORTAL_MINT_PK,
  TBTC_MINT_PK,
  WETH_PORTAL_MINT_PK,
  WSOL_PK,
} from '@dual-finance/dip';
import { detectEmbeddedInSquadsIframe, SquadsEmbeddedWalletAdapter } from '@sqds/iframe-adapter';
import { SnackbarProvider } from 'notistack';
import {
  API_URL_DEVNET,
  DUAL_API_DEVNET,
  DUAL_API_MAINNET,
  EXPLORER,
  LOCALNET,
  RPC_URL_MAINNET,
} from '../../config/config';
import { EnvironmentContextProvider } from '../../EnvironmentContext';
import { useQueryParam } from '../../hooks/useQueryParams';
import {
  CHAI_MINT_PK,
  USDY_MINT_PK,
  ALL_MINT_PK,
  DEAN_MINT_PK,
  T_MINT_PK,
  DUAL_DEV_MINT_PK,
  PYTH_MSOL_DEV_PK,
  WSTETH_MINT_PK,
  GUAC_MINT_PK,
  RETH_MINT_PK,
  SLCL_MINT_PK,
  NOS_MINT_PK,
  BUTTER_MINT_PK,
  GECKO_MINT_PK,
  ELON_MINT_PK,
  GOFX_MINT_PK,
  MOUTAI_MINT_PK,
  PYTH_MINT_PK,
  DRAKO_MINT_PK,
  BANX_MINT_PK,
  DUALSOL_MINT_PK,
  ADVOCAT_MINT_PK,
  SAMO_MINT_PK,
  LABS_MINT_PK,
  SCOIN_MINT_PK,
} from '../../constants/addresses';

const networkConfigs: Record<string, string> = {
  localhost: LOCALNET,
  devnet: API_URL_DEVNET,
  mainnet: RPC_URL_MAINNET,
};

export default function ContextProvider(props: React.PropsWithChildren) {
  const cluster = useQueryParam('cluster');
  const legacyAdapter = useQueryParam('legacyAdapter');
  const features = useFeatureFlags();
  const isDev = window.location.host.split('.')[0] === 'dev';
  const isLocal = window.location.host.includes('localhost');
  const [savedNetwork, setNetwork] = useLocalStorage('network', '');
  const [savedExplorer, setExplorer] = useLocalStorage('explorer', EXPLORER.solscan);

  const network = useMemo(() => {
    if (savedNetwork !== '') {
      return savedNetwork;
    }
    if (process.env.REACT_APP_RPC) {
      return process.env.REACT_APP_RPC;
    }
    if (cluster !== null && cluster in networkConfigs) {
      return networkConfigs[cluster];
    }
    if (isDev || isLocal) {
      return networkConfigs.devnet;
    }
    return networkConfigs.mainnet;
  }, [savedNetwork, cluster, isDev, isLocal]);
  const environment = useMemo(
    () =>
      network === networkConfigs.devnet || network === networkConfigs.localhost
        ? {
            network,
            explorer: savedExplorer,
            settings: {
              setNetwork,
              setExplorer,
            },
            ASSETS: {
              BTC: TBTC_MINT_PK,
              wBTC: BTC_DEV_MINT_PK,
              ETH: ETH_DEV_MINT_PK,
              SOL: WSOL_PK,
              USDC: USDC_DEV_MINT_PK,
              MNGO: MNGO_MINT_PK,
              BONK: BONK_DEV_MINT_PK,
              RAY: RAY_DEV_MINT_PK,
              DUAL: DUAL_DEV_MINT_PK,
              DEAN: DEAN_MINT_PK,
              T: T_MINT_PK,
              CHAI: CHAI_MINT_PK,
              USDY: USDY_MINT_PK,
              ALL: ALL_MINT_PK,
              wstETH: WSTETH_MINT_PK,
              rETH: RETH_MINT_PK,
              mSOL: MSOL_MINT_PK,
              jitoSOL: JITOSOL_MINT_PK,
              GUAC: GUAC_MINT_PK,
              SLCL: SLCL_MINT_PK,
              NOS: NOS_MINT_PK,
              BUTTER: BUTTER_MINT_PK,
              GECKO: GECKO_MINT_PK,
              ELON: ELON_MINT_PK,
              GOFX: GOFX_MINT_PK,
              MOUTAI: MOUTAI_MINT_PK,
              PYTH: PYTH_MINT_PK,
              DRAKO: DRAKO_MINT_PK,
              BANX: BANX_MINT_PK,
              DUALSOL: DUALSOL_MINT_PK,
              ADVOCAT: ADVOCAT_MINT_PK,
              SAMO: SAMO_MINT_PK,
              LABS: LABS_MINT_PK,
              SCOIN: SCOIN_MINT_PK,
            },
            DUAL_API: DUAL_API_DEVNET,
            PYTH: {
              BTC: PYTH_BTC_DEV_PK,
              ETH: PYTH_ETH_DEV_PK,
              SOL: PYTH_SOL_DEV_PK,
              MNGO: PYTH_MNGO_DEV_PK,
              mSOL: PYTH_MSOL_DEV_PK,
              jitoSOL: PYTH_JITOSOL_DEV_PK,
            },
            features,
          }
        : {
            network,
            explorer: savedExplorer,
            settings: {
              setNetwork,
              setExplorer,
            },
            ASSETS: {
              BTC: TBTC_MINT_PK,
              wBTC: WBTC_PORTAL_MINT_PK,
              ETH: WETH_PORTAL_MINT_PK,
              SOL: WSOL_PK,
              USDC: USDC_MINT_PK,
              MNGO: MNGO_MINT_PK,
              BONK: BONK_MINT_PK,
              RAY: RAY_MINT_PK,
              DUAL: DUAL_MINT_PK,
              DEAN: DEAN_MINT_PK,
              T: T_MINT_PK,
              CHAI: CHAI_MINT_PK,
              USDY: USDY_MINT_PK,
              ALL: ALL_MINT_PK,
              wstETH: WSTETH_MINT_PK,
              rETH: RETH_MINT_PK,
              mSOL: MSOL_MINT_PK,
              jitoSOL: JITOSOL_MINT_PK,
              GUAC: GUAC_MINT_PK,
              SLCL: SLCL_MINT_PK,
              NOS: NOS_MINT_PK,
              BUTTER: BUTTER_MINT_PK,
              GECKO: GECKO_MINT_PK,
              ELON: ELON_MINT_PK,
              GOFX: GOFX_MINT_PK,
              MOUTAI: MOUTAI_MINT_PK,
              PYTH: PYTH_MINT_PK,
              DRAKO: DRAKO_MINT_PK,
              BANX: BANX_MINT_PK,
              DUALSOL: DUALSOL_MINT_PK,
              ADVOCAT: ADVOCAT_MINT_PK,
              SAMO: SAMO_MINT_PK,
              LABS: LABS_MINT_PK,
              SCOIN: SCOIN_MINT_PK,
            },
            DUAL_API: DUAL_API_MAINNET,
            PYTH: {
              BTC: PYTH_BTC_PK,
              ETH: PYTH_ETH_PK,
              SOL: PYTH_SOL_PK,
              MNGO: PYTH_MNGO_PK,
              mSOL: PYTH_MSOL_PK,
              jitoSOL: PYTH_JITOSOL_PK,
            },
            features,
          },
    [features, network, savedExplorer, setExplorer, setNetwork]
  );

  // @solana/wallet-adapter-wallets includes all the adapters, but not all are updated to the wallet-adapter-base standard
  // Wallets compatible with @solana/wallet-adapter-base will be include automatically
  // Other wallets that are not yet compatible with the new standard will need to be explictly referenced below
  const wallets = useMemo(() => {
    // NOTE: The code below seems to be flaky on initial load, assuming that 'solflare' is
    //       not in the initial window.  Because of this, it starts working on later refreshes.
    //       Disabling until there is confidence in this approach.

    const adapters = [];
    if (legacyAdapter?.includes('phantom')) {
      adapters.push(new PhantomWalletAdapter());
    }
    // if ('backpack' in window || 'glow' in window || 'phantom' in window || 'solflare' in window) {
    //   return adapters;
    // }

    const whitelistedWallets = detectEmbeddedInSquadsIframe()
      ? [new SquadsEmbeddedWalletAdapter('https://iframe-preview.squads.so')]
      : [new PhantomWalletAdapter(), new SolflareWalletAdapter(), new GlowWalletAdapter()];
    return whitelistedWallets;
  }, [legacyAdapter]);
  return (
    <SnackbarProvider>
      <ConnectionProvider endpoint={network}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <EnvironmentContextProvider environment={environment}>{props.children}</EnvironmentContextProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </SnackbarProvider>
  );
}

function useFeatureFlags() {
  return {
    mango: process.env.REACT_APP_FF_MANGO !== '',
  };
}
