import { Connection, PublicKey } from '@solana/web3.js';
import { getAccount } from '@solana/spl-token';
import { TokenAccount, TokenAccountJSON } from './types';

interface TokenAccountCache {
  data: TokenAccountJSON;
  timestamp: number;
}

export async function fetchTokenAccountWithCache(connection: Connection, address: PublicKey): Promise<TokenAccount> {
  const cacheKey = `token-account-${address.toString()}`;
  const cached = localStorage.getItem(cacheKey);
  if (cached) {
    const { data } = JSON.parse(cached) as TokenAccountCache;
    return TokenAccount.fromJSON(data);
  }
  const account = await getAccount(connection, address);
  const tokenAccount = new TokenAccount(account);
  localStorage.setItem(cacheKey, JSON.stringify({ timestamp: new Date().getTime(), data: tokenAccount.toJSON() }));
  return tokenAccount;
}
