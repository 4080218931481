import { VariantType } from 'notistack';
import { PublicKey } from '@solana/web3.js';
import { BN } from '@coral-xyz/anchor';
import { JsonMetadata } from '@metaplex-foundation/mpl-token-metadata';

export type BalanceHookOptionalArgs = Partial<{
  publicKey: string;
}>;
export type NotificationType = (variant: VariantType, message: string, signature?: string, network?: string) => void;

// Created using https://github.com/cdhiraj40/anchor-tsmodel-transpiler
export interface SOState {
  soName: string;
  authority: PublicKey;
  optionsAvailable: BN;
  optionExpiration: BN;
  subscriptionPeriodEnd: BN;
  baseDecimals: number;
  quoteDecimals: number;
  baseMint: PublicKey;
  quoteMint: PublicKey;
  quoteAccount: PublicKey;
  lotSize: BN;
  stateBump: number;
  vaultBump: number;
  strikes: BN[];
}

/**
 * Staking option properties that are cached
 */
export interface SoFields {
  soName: string;
  authority: PublicKey;
  optionExpiration: BN;
  subscriptionPeriodEnd: BN;
  baseDecimals: number;
  quoteDecimals: number;
  baseMint: PublicKey;
  quoteMint: PublicKey;
  quoteAccount: PublicKey;
  lotSize: BN;
  stateBump: number;
  vaultBump: number;
}

export interface SoJSON {
  soName: string;
  authority: string;
  optionExpiration: number;
  subscriptionPeriodEnd: number;
  baseDecimals: number;
  quoteDecimals: number;
  baseMint: string;
  quoteMint: string;
  quoteAccount: string;
  lotSize: number;
  stateBump: number;
  vaultBump: number;
}

export enum LongOrShort {
  long = 'LONG',
  short = 'SHORT',
}

export interface Position {
  // Number of lots of the option token the user has
  quantity: number;
  // Lot size in number of atomic units of base token per option
  lotSize: number;
  // Number of base atoms per full token
  baseAtomsPerToken: number;
  // Number of quote atoms per full token
  quoteAtomsPerToken?: number;
  // Expiration string that is human readable
  expiration: string;
  // Expiration in seconds since epoch
  expirationInt: number;
  // Strike price for the DIP
  strike: number;
  // String for "UPSIDE" or "DOWNSIDE"
  upOrDown: DipType;
  // Public key of this DIPState
  statePk: PublicKey;
  // Mint of the base token
  baseMint: PublicKey;
  // Mint of the quote token
  quoteMint: PublicKey;
  // "LONG" or "SHORT". Long is for MM, Short is for user deposit.
  longOrShort: LongOrShort;
  // Whether this is a staking option or DIP
  poolType: PoolType;
  // TODO: Make this nullable
  // Only used for staking options
  soName: string;
  // Only used for issued SOs
  authority?: PublicKey;
  // Mint of the locked up token in the case of a GSO with a different mint.
  lockupMint?: PublicKey;
}

// TODO: Fix these names, for example, make subscription match program. Be
// consistent with base. Strike should have units.
export interface GsoParams {
  key: React.Key;
  expiration: string;
  expirationInt: number;
  subscription: string;
  subscriptionInt: number;
  gsoStatePk: PublicKey;
  soStatePk: PublicKey;
  base: PublicKey;
  quote: PublicKey;
  lockupMint: PublicKey;
  soName: string;
  strike: number;
  lockupRatio: number;
}

export enum DipType {
  upside = 'UPSIDE',
  downside = 'DOWNSIDE',
}

export enum PoolType {
  stakingOption = 'Staking Option',
  reverseStakingOption = 'Reverse Staking Option',
  gso = 'Collateral',
  dip = 'DIP',
  longDip = 'Option',
  lso = 'Liquidity',
  loyalty = 'Loyalty',
  pog = 'Governance',
  airdrop = 'Airdrop',
  partner = 'Partner',
}

export enum ExerciseSettlement {
  physical = 'Physical',
  hybrid = 'Hybrid',
  cash = 'Cash',
}

export enum PricingSource {
  backstop = 'Backstop',
  streaming = 'Streaming',
}

export type Dip = {
  apy: number;
  expiration: string;
  expirationInt: number;
  key: string;
  pk: PublicKey;
  premium: number;
  baseMint: PublicKey;
  quoteMint: PublicKey;
  strike: number;
  upOrDown: DipType;
  pricingSource: PricingSource;
};

export interface MintFields {
  address: PublicKey;
  decimals: number;
  metadata: JsonMetadata;
  supply?: bigint;
}

export interface MintJSON {
  address: string;
  decimals: number;
  metadata: JsonMetadata;
}

export class MintAccount {
  readonly address: PublicKey;

  readonly decimals: number;

  readonly metadata: JsonMetadata;

  readonly supply: bigint | undefined;

  constructor(mint: MintFields) {
    this.address = mint.address;
    this.decimals = mint.decimals;
    this.metadata = mint.metadata;
    this.supply = mint.supply;
  }

  toJSON(): MintJSON {
    return {
      address: this.address.toString(),
      decimals: this.decimals,
      metadata: this.metadata,
    };
  }

  static fromJSON(mint: MintJSON): MintAccount {
    return new MintAccount({ address: new PublicKey(mint.address), decimals: mint.decimals, metadata: mint.metadata });
  }
}

export interface TokenAccountFields {
  address: PublicKey;
  mint: PublicKey;
  owner: PublicKey;
}

export interface TokenAccountJSON {
  address: string;
  mint: string;
  owner: string;
}

/**
 * Wrapper class for handling and caching token accounts;
 * doesn't persist token amount since its a mutable field.
 */
export class TokenAccount {
  readonly address: PublicKey;

  readonly mint: PublicKey;

  readonly owner: PublicKey;

  constructor(tokenAccount: TokenAccountFields) {
    this.address = tokenAccount.address;
    this.mint = tokenAccount.mint;
    this.owner = tokenAccount.owner;
  }

  toJSON(): TokenAccountJSON {
    return {
      address: this.address.toString(),
      mint: this.mint.toString(),
      owner: this.owner.toString(),
    };
  }

  static fromJSON(tokenAccount: TokenAccountJSON): TokenAccount {
    return new TokenAccount({
      address: new PublicKey(tokenAccount.address),
      mint: new PublicKey(tokenAccount.mint),
      owner: new PublicKey(tokenAccount.owner),
    });
  }
}
