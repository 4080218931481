import {
  BONK_DEV_MINT_PK,
  BONK_MINT_PK,
  BTC_DEV_MINT_PK,
  DUAL_MINT_PK,
  ETH_DEV_MINT_PK,
  JITOSOL_MINT_PK,
  MNGO_MINT_PK,
  MSOL_MINT_PK,
  NUM_SPL_ATOMS_PER_TOKEN as DIP_SDK_NUM_SPL_ATOMS_PER_TOKEN,
  RAY_DEV_MINT_PK,
  RAY_MINT_PK,
  USDC_DEV_MINT_PK,
  USDC_MINT_PK,
  WBTC_PORTAL_MINT_PK,
  TBTC_MINT_PK,
  WETH_PORTAL_MINT_PK,
  WSOL_PK,
} from '@dual-finance/dip';
import {
  CHAI_MINT_PK,
  USDY_MINT_PK,
  ALL_MINT_PK,
  DEAN_MINT_PK,
  T_MINT_PK,
  DUAL_DEV_MINT_PK,
  WSTETH_MINT_PK,
  GUAC_MINT_PK,
  RETH_MINT_PK,
  SLCL_MINT_PK,
  NOS_MINT_PK,
  BUTTER_MINT_PK,
  GECKO_MINT_PK,
  ELON_MINT_PK,
  GOFX_MINT_PK,
  MOUTAI_MINT_PK,
  PYTH_MINT_PK,
  DRAKO_MINT_PK,
  BANX_MINT_PK,
  DUALSOL_MINT_PK,
  ADVOCAT_MINT_PK,
  SAMO_MINT_PK,
  LABS_MINT_PK,
  SCOIN_MINT_PK,
} from './addresses';

export const STAKING_OPTIONS_STATE_SIZE = 1150;
export const GSO_STATE_SIZE = 1000;
export const SINGLE_STRIKE_SO = 0;

// Most recent breaking change to account structure for DIP State
export const LATEST_ACCOUNT_RESTRUCTURE_TIME = 1667779200;

export const NUM_USDC_ATOMS_PER_TOKEN = 10 ** 6;
export const NUM_SPL_ATOMS_PER_TOKEN = {
  ...DIP_SDK_NUM_SPL_ATOMS_PER_TOKEN,
  [TBTC_MINT_PK.toBase58()]: 10 ** 8,
  [DUAL_MINT_PK.toBase58()]: 10 ** 6,
  [DUAL_DEV_MINT_PK.toBase58()]: 10 ** 6,
  [DEAN_MINT_PK.toBase58()]: 10 ** 6,
  [T_MINT_PK.toBase58()]: 10 ** 8,
  [CHAI_MINT_PK.toBase58()]: 10 ** 8,
  [USDY_MINT_PK.toBase58()]: 10 ** 6,
  [ALL_MINT_PK.toBase58()]: 10 ** 6,
  [WSTETH_MINT_PK.toBase58()]: 10 ** 8,
  [GUAC_MINT_PK.toBase58()]: 10 ** 5,
  [RETH_MINT_PK.toBase58()]: 10 ** 8,
  [SLCL_MINT_PK.toBase58()]: 10 ** 9,
  [NOS_MINT_PK.toBase58()]: 10 ** 6,
  [BUTTER_MINT_PK.toBase58()]: 10 ** 9,
  [GECKO_MINT_PK.toBase58()]: 10 ** 6,
  [ELON_MINT_PK.toBase58()]: 10 ** 4,
  [GOFX_MINT_PK.toBase58()]: 10 ** 9,
  [MOUTAI_MINT_PK.toBase58()]: 10 ** 6,
  [PYTH_MINT_PK.toBase58()]: 10 ** 6,
  [DRAKO_MINT_PK.toBase58()]: 10 ** 5,
  [BANX_MINT_PK.toBase58()]: 10 ** 9,
  [DUALSOL_MINT_PK.toBase58()]: 10 ** 9,
  [ADVOCAT_MINT_PK.toBase58()]: 10 ** 6,
  [SAMO_MINT_PK.toBase58()]: 10 ** 9,
  [LABS_MINT_PK.toBase58()]: 10 ** 9,
  [SCOIN_MINT_PK.toBase58()]: 10 ** 9,
};

export const DECIMALS_PER_TOKEN = {
  [TBTC_MINT_PK.toBase58()]: 8,
  [WBTC_PORTAL_MINT_PK.toBase58()]: 8,
  [BTC_DEV_MINT_PK.toBase58()]: 8,
  [WETH_PORTAL_MINT_PK.toBase58()]: 6,
  [ETH_DEV_MINT_PK.toBase58()]: 6,
  [WSOL_PK.toBase58()]: 9,
  [USDC_MINT_PK.toBase58()]: 6,
  [USDC_DEV_MINT_PK.toBase58()]: 6,
  [DUAL_MINT_PK.toBase58()]: 6,
  [DUAL_DEV_MINT_PK.toBase58()]: 6,
  [MNGO_MINT_PK.toBase58()]: 6,
  [BONK_MINT_PK.toBase58()]: 5,
  [BONK_DEV_MINT_PK.toBase58()]: 5,
  [RAY_MINT_PK.toBase58()]: 6,
  [RAY_DEV_MINT_PK.toBase58()]: 6,
  [MSOL_MINT_PK.toBase58()]: 9,
  [JITOSOL_MINT_PK.toBase58()]: 9,
  [GUAC_MINT_PK.toBase58()]: 5,
  [SLCL_MINT_PK.toBase58()]: 9,
  [NOS_MINT_PK.toBase58()]: 6,
  [BUTTER_MINT_PK.toBase58()]: 9,
  [GECKO_MINT_PK.toBase58()]: 6,
  [ELON_MINT_PK.toBase58()]: 4,
  [GOFX_MINT_PK.toBase58()]: 9,
  [MOUTAI_MINT_PK.toBase58()]: 6,
  [PYTH_MINT_PK.toBase58()]: 6,
  [DRAKO_MINT_PK.toBase58()]: 5,
  [BANX_MINT_PK.toBase58()]: 9,
  [DUALSOL_MINT_PK.toBase58()]: 9,
  [ADVOCAT_MINT_PK.toBase58()]: 6,
  [SAMO_MINT_PK.toBase58()]: 9,
  [LABS_MINT_PK.toBase58()]: 9,
  [SCOIN_MINT_PK.toBase58()]: 9,
};

export const DIP_DECIMALS = 6;
export const NUM_DIP_ATOMS_PER_TOKEN = 10 ** DIP_DECIMALS;
