import { PublicKey } from '@solana/web3.js';

export const DUAL_DEV_MINT_PK = new PublicKey('HLcsChtA7BE9g9QfTqM4xKdz8XxCDEbs1ZH3LxfuSW9u');
export const PYTH_MSOL_DEV_PK = new PublicKey('9a6RNx3tCu1TSs6TBSfV2XRXEPEZXQ6WB7jRojZRvyeZ');
export const DEAN_MINT_PK = new PublicKey('Ds52CDgqdWbTWsua1hgT3AuSSy4FNx2Ezge1br3jQ14a');
export const T_MINT_PK = new PublicKey('4Njvi3928U3figEF5tf8xvjLC5GqUN33oe4XTJNe7xXC');
export const CHAI_MINT_PK = new PublicKey('3jsFX1tx2Z8ewmamiwSU851GzyzM2DJMq7KWW5DM8Py3');
export const USDY_MINT_PK = new PublicKey('A1KLoBrKBde8Ty9qtNQUtq3C2ortoC3u7twggz7sEto6');
export const ALL_MINT_PK = new PublicKey('BaoawH9p2J8yUK9r5YXQs3hQwmUJgscACjmTkh8rMwYL');
export const WSTETH_MINT_PK = new PublicKey('ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo');
export const GUAC_MINT_PK = new PublicKey('AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR');
export const RETH_MINT_PK = new PublicKey('9UV2pC1qPaVMfRv8CF7qhv7ihbzR91pr2LX9y2FDfGLy');
export const SLCL_MINT_PK = new PublicKey('SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV');
export const NOS_MINT_PK = new PublicKey('nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7');
export const BUTTER_MINT_PK = new PublicKey('5cQe5Fo5LnRM5wpaHV7rDyvBHg1cRSyCwDdLRnuQWniu');
export const GECKO_MINT_PK = new PublicKey('6CNHDCzD5RkvBWxxyokQQNQPjFWgoHF94D7BmC73X6ZK');
export const ELON_MINT_PK = new PublicKey('7ZCm8WBN9aLa3o47SoYctU6iLdj7wkGG5SV2hE5CgtD5');
export const GOFX_MINT_PK = new PublicKey('GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD');
export const MOUTAI_MINT_PK = new PublicKey('45EgCwcPXYagBC7KqBin4nCFgEZWN7f3Y6nACwxqMCWX');
export const PYTH_MINT_PK = new PublicKey('HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3');
export const DRAKO_MINT_PK = new PublicKey('drakduQWnTS89CdTUdgHmZzEkN6reBLYqrk8rzVhU53');
export const BANX_MINT_PK = new PublicKey('BANXbTpN8U2cU41FjPxe2Ti37PiT5cCxLUKDQZuJeMMR');
export const DUALSOL_MINT_PK = new PublicKey('DUAL6T9pATmQUFPYmrWq2BkkGdRxLtERySGScYmbHMER');
export const ADVOCAT_MINT_PK = new PublicKey('FxwbKC8s3dq8kkwJyHN5KEwDuhzawgfTmWRnfnX6jBho');
export const SAMO_MINT_PK = new PublicKey('7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU');
export const LABS_MINT_PK = new PublicKey('LABSh5DTebUcUbEoLzXKCiXFJLecDFiDWiBGUU1GpxR');
export const SCOIN_MINT_PK = new PublicKey('5qKDWkBejLtRh1UGFV7e58QEkdn2fRyH5ehVXqUYujNW');

export const DAI_MINT_PK = new PublicKey('EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o');
export const USDH_MINT_PK = new PublicKey('USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX');
export const USDT_MINT_PK = new PublicKey('Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB');
