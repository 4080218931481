import { MERKLE_VERIFIER_PK } from '@dual-finance/airdrop';
import { Connection, PublicKey } from '@solana/web3.js';
import { useEffect, useState } from 'react';
import { AIRDROPS } from '../config/config';
import { getRecipientBalances } from '../utils/airdrop';
import { useAnchorProvider } from './useAnchorProvider';

type BalanceByAirdropId = {
  [airdropId: string]: number;
};

export function useAirdropBalances(address: PublicKey | null) {
  const [, connection] = useAnchorProvider();
  const [balanceByAirdropId, setBalanceByAirdropId] = useState<BalanceByAirdropId>();

  useEffect(() => {
    if (address) {
      fetchAirdropBalances(connection, address).then(setBalanceByAirdropId).catch(console.error);
    }
  }, [address, connection]);

  return { balanceByAirdropId };
}

async function fetchAirdropBalances(connection: Connection, address: PublicKey) {
  const balanceByAirdropId: BalanceByAirdropId = {};
  for (const [airdrop, verifierState] of AIRDROPS.entries()) {
    const balances = await getRecipientBalances(airdrop);
    const index = balances.findIndex(({ account }) => account.equals(address));
    const isRecipient = index > -1;
    const receipt = isRecipient ? await fetchAirdropReceipt(connection, verifierState, index) : null;
    console.log(receipt);
    if (isRecipient && !receipt) {
      const balance = balances[index];
      balanceByAirdropId[verifierState.toString()] = balance.amount.toNumber();
    }
  }
  return balanceByAirdropId;
}

async function fetchAirdropReceipt(connection: Connection, verifierState: PublicKey, index: number) {
  const verificationData: Buffer = Buffer.alloc(8);
  verificationData.writeBigUInt64LE(BigInt(index));

  const [receipt, _receiptBump] = PublicKey.findProgramAddressSync(
    [Buffer.from('Receipt'), verifierState.toBuffer(), verificationData],
    MERKLE_VERIFIER_PK
  );

  const account = await connection.getAccountInfo(receipt);
  return account;
}
