import { lazy, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParams';
import { AirdropBanner } from '../AirdropBanner';
import styles from './Layout.module.scss';

const Header = lazy(() => import('../Header'));

export function Layout() {
  const referrer = useQueryParam('ref');
  useEffect(() => {
    if (referrer) {
      window.localStorage.setItem('ref', referrer);
    }
  }, [referrer]);
  return (
    <div className={styles.defaultLayout}>
      <AirdropBanner />
      <Header />
      <div className={styles.contentWrapper}>
        <Outlet />
      </div>
    </div>
  );
}
