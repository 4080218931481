/* eslint-disable prefer-spread */
import * as bs from 'black-scholes';
import commaNumber from 'comma-number';
import * as serum_anchor from '@project-serum/anchor';
import {
  Commitment,
  Connection,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';
import { AnchorProvider, Wallet, utils } from '@coral-xyz/anchor';
import { AnchorProvider as SerumAnchorProvider } from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from '@solana/spl-token';
import { Buffer } from 'buffer';
import { WalletError, WalletSignTransactionError, WalletTimeoutError } from '@solana/wallet-adapter-base';
import {
  optionMint,
  toBeBytes,
  vault,
  RF_RATE,
  DIP_MM_PK,
  BONK_MINT_PK,
  DUAL_MINT_PK,
  USDC_MINT_PK,
  WBTC_PORTAL_MINT_PK,
  TBTC_MINT_PK,
  WETH_PORTAL_MINT_PK,
  WSOL_PK,
  MNGO_MINT_PK,
  RAY_MINT_PK,
} from '@dual-finance/dip';
import { GSO_PK } from '@dual-finance/gso';
import { DUAL_PRICE, HELIUS_MAINNET, RPC_SENDER_URL_MAINNET, RPC_URL_MAINNET, VOL_MAP } from '../config/config';
import { NotificationType } from './types';
import { MS_PER_YEAR } from '../constants/time';
import { DIP_MM_PRICING_SEED } from '../constants/seeds';
import {
  DECIMALS_PER_TOKEN,
  NUM_DIP_ATOMS_PER_TOKEN,
  NUM_SPL_ATOMS_PER_TOKEN,
  NUM_USDC_ATOMS_PER_TOKEN,
} from '../constants/contract';
import {
  CHAI_MINT_PK,
  USDY_MINT_PK,
  DAI_MINT_PK,
  GECKO_MINT_PK,
  GUAC_MINT_PK,
  NOS_MINT_PK,
  RETH_MINT_PK,
  USDH_MINT_PK,
  USDT_MINT_PK,
  WSTETH_MINT_PK,
} from '../constants/addresses';
import { fetchMintWithCache } from './mint';
import { fetchTokenAccountWithCache } from './account';

export const calculateDownsideQuantity = (data: {
  baseMint: PublicKey;
  quoteMint: PublicKey;
  strike: number;
  quantity: number;
}) => {
  return (
    (data.quantity / calculateDownsideStrike(data)) *
    (NUM_SPL_ATOMS_PER_TOKEN[data.baseMint.toBase58()] / NUM_SPL_ATOMS_PER_TOKEN[data.quoteMint.toBase58()])
  );
};

export const calculateDownsideStrike = (data: { baseMint: PublicKey; strike: number }) => {
  return Number(((1 / data.strike) * NUM_SPL_ATOMS_PER_TOKEN[data.baseMint.toBase58()]).toPrecision(6));
};

// TODO: Reconcile this with the DIP version
export const calculateSoDownsideStrike = (data: {
  baseMint: PublicKey;
  quoteMint: PublicKey;
  strike: number;
  lotSize: number;
}) => {
  // Strike is lots of quote atoms per lot, we want to display base tokens per quote token
  const strikeQuoteAtomsPerLot = data.strike;
  const strikeQuoteAtomsPerBaseAtom = strikeQuoteAtomsPerLot / data.lotSize;
  const strikeBaseAtomsPerQuoteAtom = 1 / strikeQuoteAtomsPerBaseAtom;

  const baseAtomsPerToken = NUM_SPL_ATOMS_PER_TOKEN[data.baseMint.toBase58()];
  const quoteAtomsPerToken = NUM_SPL_ATOMS_PER_TOKEN[data.quoteMint.toBase58()];

  const strikeDisplay = (strikeBaseAtomsPerQuoteAtom * quoteAtomsPerToken) / baseAtomsPerToken;
  return strikeDisplay;
};

export function isValidPublicKey(value: string) {
  try {
    const _ = new PublicKey(value);
    return true;
  } catch (e) {
    return false;
  }
}

export function isPublicKeyOnCurve(value: string) {
  if (isValidPublicKey(value)) {
    return PublicKey.isOnCurve(value);
  }
  return false;
}

export function formatNumberToSigFig(num: number, digits = 3): string {
  // Use toLocaleString to format the number with three significant figures
  const formattedNumber = num.toLocaleString('en-US', {
    minimumSignificantDigits: digits,
    maximumSignificantDigits: digits,
  });

  return formattedNumber;
}

export function parseNumber(str: string, precision: number) {
  if (str === '.' || str === '') {
    return str;
  }
  const numberSegments = str.split('.');
  if (numberSegments.length !== 2) {
    return str;
  }
  const inputDecimals = numberSegments[1].length;
  const decimals = Math.min(inputDecimals, precision);
  if (inputDecimals > decimals) {
    return Number(Math.floor(parseFloat(str) * 10 ** decimals) / 10 ** decimals).toFixed(decimals);
  }
  if (inputDecimals === decimals) {
    return Number(Math.round(parseFloat(str) * 10 ** decimals) / 10 ** decimals).toFixed(decimals);
  }
  return Number((parseFloat(str) * 10 ** decimals) / 10 ** decimals).toFixed(decimals);
}

export const prettyFormatNumberWithDecimals = (number: number, decimals: number): string => {
  const rounded: number = Math.floor(number * 10 ** decimals) / 10 ** decimals;
  return commaNumber(rounded);
};

export const prettyFormatNumber = (number: number): string => {
  return commaNumber(number);
};

export const prettyFormatPrice = (price: number, decimals = 4): string => {
  return `$${(price >= 0.1 ? price.toFixed(2) : price.toFixed(decimals)).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

export const roundToFixed = (value: number, digits = 2) => {
  return Math.round(value * 10 ** digits) / 10 ** digits;
};

export const getDisplayDecimals = (mint: PublicKey): number => {
  if (mint.toBase58() === BONK_MINT_PK.toBase58() || mint.toString() === GUAC_MINT_PK.toString()) {
    return 8;
  }
  if (mint.toBase58() === GECKO_MINT_PK.toBase58()) {
    return 5;
  }
  return 3;
};

export const formatDate = (date: number | Date): string => {
  const _date = new Date(date);
  return _date.toDateString().split(' ').slice(1).join(' ');
};

export function msToTimeLeft(duration: number) {
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  return `${days}d ${hours}h ${minutes}m`;
}

type GetProviderOptionalArgs = Partial<{
  skipPreflight: boolean;
}>;
export function GetProvider(
  wallet: any,
  network: string,
  opts: GetProviderOptionalArgs = { skipPreflight: false }
): [SerumAnchorProvider, Connection] {
  const connection = new Connection(network, 'confirmed');
  const options = AnchorProvider.defaultOptions();
  options.skipPreflight = opts.skipPreflight;
  // Uncomment this when testing to get more explanation of contract failures.
  // options.skipPreflight = true;
  const provider = new AnchorProvider(connection, wallet as Wallet, options);
  return [provider as SerumAnchorProvider, connection];
}

// TODO: Derprecate this in favor of token balance apis
export const getMultipleTokenAccounts = async (connection: Connection, keys: string[], commitment: string) => {
  if (keys.length > 5) {
    const batches: string[][] = chunks(keys, 5);
    const batchesPromises: Promise<{ keys: string[]; array: any }>[] = batches.map((batch: string[]) => {
      const result: Promise<{ keys: string[]; array: any }> = getMultipleAccountsCore(
        connection,
        batch,
        commitment,
        'jsonParsed'
      );
      return result;
    });
    const results: { keys: string[]; array: any }[] = await Promise.all<{ keys: string[]; array: any }>(
      batchesPromises
    );
    let allKeys: string[] = [];
    let allArrays: any[] = [];
    results.forEach((result: { keys: string[]; array: any }) => {
      allKeys = allKeys.concat(result.keys);
      allArrays = allArrays.concat(result.array);
    });
    return { keys: allKeys, array: allArrays };
  }

  const result = await getMultipleAccountsCore(connection, keys, commitment, 'jsonParsed');
  const array = result.array.map((acc: { [x: string]: any; data: any }) => {
    if (!acc) {
      return undefined;
    }
    const { data, ...rest } = acc;
    const obj = {
      ...rest,
      data,
    };
    return obj;
  });
  return { keys, array };
};

function chunks<T>(array: T[], size: number): T[][] {
  return Array.apply(0, new Array(Math.ceil(array.length / size))).map((_, index) =>
    array.slice(index * size, (index + 1) * size)
  );
}

// TODO: Merge this logic with getMultipleTokenAccounts
export const getMultipleAccounts = async (connection: Connection, keys: string[], commitment: string | undefined) => {
  if (keys.length > 5) {
    const batches: string[][] = chunks(keys, 5);
    const batchesPromises: Promise<{ keys: string[]; array: any }>[] = batches.map((batch: string[]) => {
      const result: Promise<{ keys: string[]; array: any }> = getMultipleAccountsCore(
        connection,
        batch,
        commitment,
        'jsonParsed'
      );
      return result;
    });
    const results: { keys: string[]; array: any }[] = await Promise.all<{ keys: string[]; array: any }>(
      batchesPromises
    );
    let allKeys: string[] = [];
    let allArrays: any[] = [];
    results.forEach((result: { keys: string[]; array: any }) => {
      allKeys = allKeys.concat(result.keys);
      allArrays = allArrays.concat(result.array.account);
    });
    return { keys: allKeys, array: allArrays };
  }

  const publicKeys: PublicKey[] = [];
  keys.forEach((key) => {
    publicKeys.push(new PublicKey(key));
  });
  const result = await utils.rpc.getMultipleAccounts(connection, publicKeys, commitment as Commitment);

  const array = result
    .map((acc: { account: any; publicKey: PublicKey } | undefined | null) => {
      if (!acc) {
        return undefined;
      }
      return acc.account;
    })
    .filter((_: any) => _);
  return { keys, array };
};

const getMultipleAccountsCore = async (
  connection: Connection,
  keys: string[],
  commitment: string | undefined,
  encoding: string | undefined
): Promise<{ keys: string[]; array: any }> => {
  if (encoding !== 'jsonParsed' && encoding !== 'base64') {
    throw new Error();
  }
  const args = connection._buildArgs([keys], commitment as Commitment, encoding);

  // @ts-ignore
  const unsafeRes = await connection._rpcRequest('getMultipleAccounts', args);
  if (unsafeRes.error) {
    throw new Error(`failed to get info about account ${unsafeRes.error.message as string}`);
  }

  if (unsafeRes.result.value) {
    const array = unsafeRes.result.value;
    return { keys, array };
  }

  throw new Error();
};

/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
export function readBigUInt64LE(buffer: Buffer, offset = 0) {
  const first = buffer[offset];
  const last = buffer[offset + 7];
  if (first === undefined || last === undefined) {
    throw new Error();
  }
  const lo = first + buffer[++offset] * 2 ** 8 + buffer[++offset] * 2 ** 16 + buffer[++offset] * 2 ** 24;
  const hi = buffer[++offset] + buffer[++offset] * 2 ** 8 + buffer[++offset] * 2 ** 16 + last * 2 ** 24;
  return BigInt(lo) + (BigInt(hi) << BigInt(32));
}

// TODO: remove and replace usage with `createAssociatedTokenAccountInstruction`
export function createAssociatedTokenAccountInstr(
  pubKey: PublicKey,
  mint: PublicKey,
  owner: PublicKey,
  payer: PublicKey
) {
  const data = Buffer.alloc(0);
  const keys = [
    {
      pubkey: payer,
      isSigner: true,
      isWritable: true,
    },
    {
      pubkey: pubKey,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: owner,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: mint,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  const instr = new TransactionInstruction({
    keys,
    programId: ASSOCIATED_TOKEN_PROGRAM_ID,
    data,
  });
  return instr;
}

export async function vaultTokenAccountPk(
  strikePrice: number,
  expirationInt: number,
  splMint: PublicKey,
  owner: PublicKey,
  usdcMintPk: PublicKey
) {
  const vaultTokenMint = vault(strikePrice, expirationInt, splMint, usdcMintPk);
  return getAssociatedTokenAddress(vaultTokenMint, owner, true);
}

export async function optionTokenAccountPk(
  strikePrice: number,
  expirationInt: number,
  splMint: PublicKey,
  owner: PublicKey,
  usdcMintPk: PublicKey
) {
  const optionTokenMint = optionMint(strikePrice, expirationInt, splMint, usdcMintPk);
  return getAssociatedTokenAddress(optionTokenMint, owner, true);
}

// TODO: Remove this and use an anchor type.
export function parseDipState(buf: Buffer) {
  const strike = Number(readBigUInt64LE(buf, 8));
  const expiration = Number(readBigUInt64LE(buf, 16));
  const baseMint = new PublicKey(buf.slice(24, 56));
  const vaultMint = new PublicKey(buf.slice(56, 88));
  const vaultMintBump = Number(buf.readUInt8(88));
  const vaultBase = new PublicKey(buf.slice(89, 121));
  const vaultBaseBump = Number(buf.readUInt8(121));
  const optionTokenMint = new PublicKey(buf.slice(122, 154));
  const optionBump = Number(buf.readUInt8(154));
  const vaultQuote = new PublicKey(buf.slice(155, 187));
  const vaultQuoteBump = Number(buf.readUInt8(187));
  const quoteMint = new PublicKey(buf.slice(188, 220));
  return {
    strike,
    expiration,
    baseMint,
    vaultMint,
    vaultMintBump,
    vaultBase,
    vaultBaseBump,
    optionMint: optionTokenMint,
    optionBump,
    vaultUsdc: vaultQuote,
    vaultQuote,
    vaultUsdcBump: vaultQuoteBump,
    vaultQuoteBump,
    usdcMint: quoteMint,
    quoteMint,
  };
}

export function parseGsoState(buf: Buffer) {
  const periodNum = Number(readBigUInt64LE(buf, 8));
  const subscriptionPeriodEnd = Number(readBigUInt64LE(buf, 16));
  const lockupRatioTokensPerMillion = Number(readBigUInt64LE(buf, 24));
  const gsoStateBump = Number(buf.readUInt8(32));
  const soAuthorityBump = Number(buf.readUInt8(33));
  const xBaseMintBump = Number(buf.readUInt8(34));
  const baseVaultBump = Number(buf.readUInt8(35));
  const strike = Number(readBigUInt64LE(buf, 36));
  const soNameLengthBytes = Number(buf.readUInt8(44));
  // @ts-ignore
  const soName = String.fromCharCode.apply(String, buf.slice(48, 48 + soNameLengthBytes));
  const soStateOffset = 48 + soNameLengthBytes;
  const stakingOptionsState = new PublicKey(buf.slice(soStateOffset, soStateOffset + 32));
  const authority = new PublicKey(buf.slice(soStateOffset + 32, soStateOffset + 32 + 32));
  let baseMint = new PublicKey(buf.slice(soStateOffset + 64, soStateOffset + 64 + 32));
  if (baseMint.toBase58() === '11111111111111111111111111111111') {
    // Backwards compatibility hack.
    baseMint = BONK_MINT_PK;
  }
  let lockupPeriodEnd = Number(readBigUInt64LE(buf.slice(soStateOffset + 96, soStateOffset + 96 + 32)));
  if (lockupPeriodEnd === 0) {
    // Backwards compatibility hack for subscription period
    lockupPeriodEnd = subscriptionPeriodEnd;
  }
  return {
    periodNum,
    subscriptionPeriodEnd,
    lockupRatioTokensPerMillion,
    gsoStateBump,
    soAuthorityBump,
    xBaseMintBump,
    baseVaultBump,
    strike,
    soNameLengthBytes,
    soName,
    stakingOptionsState,
    authority,
    baseMint,
    lockupPeriodEnd,
  };
}

export async function getGsoLockupMint(gsoStatePk: PublicKey, connection: Connection) {
  const [gsoLockupVault, _gsoLockupVaultBump] = PublicKey.findProgramAddressSync(
    [Buffer.from(utils.bytes.utf8.encode('base-vault')), gsoStatePk.toBuffer()],
    GSO_PK
  );
  const tokenAccount = await fetchTokenAccountWithCache(connection, gsoLockupVault);

  return tokenAccount.mint;
}

export function formatNum(num: number, fractionDigits = 4) {
  return num.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: fractionDigits });
}

export async function pricingAddress(strike: number, expiration: number, baseMint: PublicKey, quoteMint: PublicKey) {
  const [dipMmPricing, _dipMmPricingBump] = await PublicKey.findProgramAddress(
    [
      Buffer.from(utils.bytes.utf8.encode(DIP_MM_PRICING_SEED)),
      toBeBytes(strike),
      toBeBytes(expiration),
      baseMint.toBuffer(),
      quoteMint.toBuffer(),
    ],
    DIP_MM_PK
  );
  return dipMmPricing;
}

const mainnetNetworks = [RPC_URL_MAINNET, HELIUS_MAINNET];
export function getSenderNetwork(network: string) {
  if (mainnetNetworks.includes(network)) {
    return RPC_SENDER_URL_MAINNET;
  }
  return network;
}

export async function checkBurnedAccount(
  connection: Connection,
  userAccount: PublicKey,
  mint: PublicKey,
  provider: SerumAnchorProvider
) {
  if (!(await connection.getAccountInfo(userAccount))) {
    const transactionCreate = new serum_anchor.web3.Transaction();
    const createAccnt = createAssociatedTokenAccountInstr(userAccount, mint, provider.publicKey, provider.publicKey);
    transactionCreate.add(createAccnt);
    const [sender] = GetProvider(provider.wallet, getSenderNetwork(connection.rpcEndpoint));
    await sender.sendAndConfirm(transactionCreate, [], {
      commitment: 'confirmed',
    });
  }
}

export async function handleWalletTxCall(fn: () => Promise<string | undefined>, notify: NotificationType) {
  try {
    const signature = await fn();
    return signature;
  } catch (e: any) {
    if (e instanceof WalletSignTransactionError) {
      return;
    }
    const level = e instanceof WalletTimeoutError ? 'warning' : 'error';
    notify(level, e instanceof WalletError ? e.message : 'Error');
    console.log(e);
  }
}

export function calcDualAPY(
  amount: number,
  strike: number,
  expiration: number,
  optionAmount: number,
  tokenPrice: number
) {
  if (amount > 0) {
    const durationMs = expiration * 1_000 - Date.now();
    const fractionOfYear = durationMs / MS_PER_YEAR;
    const vol = VOL_MAP[DUAL_MINT_PK.toBase58()];
    const lsoStrike = (1 + Math.abs(strike - tokenPrice) / tokenPrice) * DUAL_PRICE * NUM_DIP_ATOMS_PER_TOKEN;
    const lsoStrikeRounded = (Math.floor((lsoStrike + 1_000) / 1_000) * 1_000) / NUM_DIP_ATOMS_PER_TOKEN;
    const lsoPrice =
      bs.blackScholes(DUAL_PRICE, lsoStrikeRounded, fractionOfYear, vol, RF_RATE, 'call') * NUM_USDC_ATOMS_PER_TOKEN;
    const lsoPremium = lsoPrice * optionAmount;
    const notionalDeposit = amount * tokenPrice;
    const earnedRatio = lsoPremium / notionalDeposit;
    const apr = earnedRatio / fractionOfYear / NUM_USDC_ATOMS_PER_TOKEN;
    const apy = (1 + apr * fractionOfYear) ** (1 / fractionOfYear) - 1;
    return apy;
  }
  return 0;
}

export async function tokenBalanceByMint(connection: Connection, userPublicKey: PublicKey) {
  const parsedTokenAccountsByOwner = await connection.getParsedTokenAccountsByOwner(userPublicKey, {
    programId: TOKEN_PROGRAM_ID,
  });
  const mintToAmount: Map<string, number> = new Map();
  for (const token of parsedTokenAccountsByOwner.value) {
    const { amount, decimals } = token.account.data.parsed.info.tokenAmount;
    const tokenAmount = amount / 10 ** decimals;
    mintToAmount.set(token.account.data.parsed.info.mint, tokenAmount);
  }

  return mintToAmount;
}

export async function fetchMintDecimals(connection: Connection, mint: PublicKey) {
  return DECIMALS_PER_TOKEN[mint.toString()] || (await fetchMintWithCache(connection, mint)).decimals;
}

export const serializeStateToURL = (state: { [key: string]: string | null }, existingState?: URLSearchParams) => {
  const params = existingState || new URLSearchParams();
  Object.entries(state).forEach(([k, value]) => {
    if (value) {
      params.set(k, value);
    }
  });
  return params.toString();
};

const USDC = USDC_MINT_PK.toString();
const USDT = USDT_MINT_PK.toString();
const DAIPO = DAI_MINT_PK.toString();
const USDH = USDH_MINT_PK.toString();
const CHAI = CHAI_MINT_PK.toString();
const USDY = USDY_MINT_PK.toString();
const stables = [USDC, USDT, DAIPO, USDH, CHAI, USDY];
const partners = [MNGO_MINT_PK.toString(), RAY_MINT_PK.toString(), NOS_MINT_PK.toString()];

const WBTCPO = WBTC_PORTAL_MINT_PK.toString();
const TBTC = TBTC_MINT_PK.toString();
const WSTETHPO = WSTETH_MINT_PK.toString();
const RETHPO = RETH_MINT_PK.toString();
const WETHPO = WETH_PORTAL_MINT_PK.toString();
const WSOL = WSOL_PK.toString();
const majors = [WBTCPO, TBTC, WSTETHPO, RETHPO, WETHPO, WSOL];

const BP = 0.01 / 100;

/**
 * Utility function that returns fee multiplier for exercising options,
 * based on https://github.com/Dual-Finance/staking-options/blob/b902c46e0ea78fdf7edf42967b1583c74b995743/programs/staking-options/src/common.rs#L88C18-L88C18
 * */
export function getFeeByPairAndName(baseMint: PublicKey, quoteMint: PublicKey, soName: string) {
  const isBaseStable = stables.includes(baseMint.toString());
  const isQuoteStable = stables.includes(quoteMint.toString());
  const isPartnerToken = partners.includes(baseMint.toString()) || partners.includes(quoteMint.toString());
  let typeFee = 350 * BP;
  const isOTC = soName.includes('OTC');
  const isMM = soName.includes('MM') || soName.includes('Loan');
  if (isOTC) {
    typeFee = 10 * BP;
  }
  if (isMM) {
    typeFee = 25 * BP;
  }

  if (isBaseStable && isQuoteStable) {
    return 5 * BP;
  }
  if (isPartnerToken) {
    return Math.min(25 * BP, typeFee);
  }

  const isBaseMajor = majors.includes(baseMint.toString());
  const isQuoteMajor = majors.includes(quoteMint.toString());

  if ((isBaseMajor && isQuoteStable) || (isBaseStable && isQuoteMajor)) {
    return Math.min(10 * BP, typeFee);
  }

  if (isBaseMajor && isQuoteMajor) {
    return 5 * BP;
  }

  return Math.min(350 * BP, typeFee);
}

export function roundUpToThreeSigFigs(input: number) {
  if (input === 0) {
    return 0;
  }
  const power = Math.floor(Math.log10(Math.abs(input))) - 2;
  const magnitude = 10 ** power;
  return Math.ceil(input / magnitude) * magnitude;
}
