import { ComputeBudgetProgram, Connection, PublicKey, Transaction } from '@solana/web3.js';
import { Airdrop as AirdropHelper } from '@dual-finance/airdrop';
import { WalletContextState } from '@solana/wallet-adapter-react';
import BN from 'bn.js';
import Papa from 'papaparse';
import { createAssociatedTokenAccountIdempotentInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import { GetProvider, getSenderNetwork } from './utils';
import { fetchTokenAccountWithCache } from './account';

export async function lookupAndClaimAirdrop(
  wallet: WalletContextState,
  network: string,
  proposal: PublicKey,
  soSelected: string
) {
  const [provider, _connection] = GetProvider(wallet, network);

  const airdrop = new AirdropHelper(network);

  let claimTransaction: Transaction;
  const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
    units: 400_000,
  });
  const modifyComputePrice = ComputeBudgetProgram.setComputeUnitPrice({
    microLamports: 1_000_000,
  });
  if (soSelected.toLowerCase().includes('governance')) {
    claimTransaction = await airdrop.createClaimGovernanceTransaction(
      proposal,
      provider.wallet.publicKey, // recipient
      provider.wallet.publicKey // authority
    );
  } else {
    const amountsByRecipient = await getRecipientBalances(soSelected);
    if (
      !amountsByRecipient.some((recipient) => recipient.account.toString() === provider.wallet.publicKey.toString())
    ) {
      throw new Error('Recipient does not qualify for airdrop');
    }
    claimTransaction = await airdrop.createClaimMerkleTransaction(
      proposal,
      provider.wallet.publicKey, // recipient
      amountsByRecipient,
      provider.wallet.publicKey // authority
    );
  }
  claimTransaction.add(modifyComputeUnits);
  claimTransaction.add(modifyComputePrice);
  const [sender] = GetProvider(provider.wallet, getSenderNetwork(network));
  const signature = await sender.sendAndConfirm(claimTransaction, [], { skipPreflight: true });

  return signature;
}

export async function getAirdropVaultMint(connection: Connection, proposal: PublicKey) {
  const airdrop = new AirdropHelper(connection.rpcEndpoint);

  const vault = airdrop.getVaultAddress(proposal);

  const account = await fetchTokenAccountWithCache(connection, vault);

  return account.mint;
}

export async function getRecipientBalances(soSelected: string) {
  const response = await fetch(`/${soSelected}.txt`);
  console.log('RESPONSE', `/${soSelected}.txt`);
  const responseText = await response.text();
  const data = Papa.parse<string[]>(responseText);
  const amountsByRecipient: { account: PublicKey; amount: BN }[] = [];
  for (const line of data.data) {
    const parsed = line;
    const [key, amount] = parsed;
    if (!key) {
      continue;
    }
    amountsByRecipient.push({ account: new PublicKey(key), amount: new BN(Number(amount)) });
  }
  return amountsByRecipient;
}

export async function fetchAirdropWalletBalance(soSelected: string, address: PublicKey) {
  const balances = await getRecipientBalances(soSelected);
  const balance = balances.find((airdropBalance) => airdropBalance.account.equals(address));

  if (!balance) {
    return undefined;
  }
  return balance;
}

export async function configMerkle(
  wallet: WalletContextState,
  mint: PublicKey,
  network: string,
  amountsByRecipient: { account: PublicKey; amount: BN }[],
  closeAuthority: PublicKey
) {
  const [provider, _connection] = GetProvider(wallet, network);

  const airdrop = new AirdropHelper(network);

  const initTx: Transaction = new Transaction();
  const source = await getAssociatedTokenAddress(mint, provider.wallet.publicKey);
  initTx.add(
    createAssociatedTokenAccountIdempotentInstruction(
      provider.wallet.publicKey,
      source,
      provider.wallet.publicKey,
      mint
    )
  );
  const [sender] = GetProvider(provider.wallet, getSenderNetwork(network));
  const createATASignature = await sender.sendAndConfirm(initTx);
  console.log('Create Associated Account', createATASignature);
  const configTx: Transaction = new Transaction();
  const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
    units: 400_000,
  });
  const modifyComputePrice = ComputeBudgetProgram.setComputeUnitPrice({
    microLamports: 1_000_000,
  });
  const { transaction } = await airdrop.createConfigMerkleTransaction(
    source,
    provider.wallet.publicKey, // authority
    new BN(0),
    undefined,
    amountsByRecipient,
    closeAuthority
  );
  configTx.add(modifyComputeUnits);
  configTx.add(modifyComputePrice);
  configTx.add(transaction);
  const signature = await sender.sendAndConfirm(configTx);
  console.log('Initialize Merkle Airdrop', signature);

  return signature;
}
